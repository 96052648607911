<template lang="pug">
  div.policy
    div.container
      h1 PUBLIC OFFER AGREEMENT
      .policy__container
        div For the Provision of Communication Equipment Maintenance Services
        div This Public Offer Agreement (hereinafter referred to as the "Agreement") sets forth the terms and conditions for the provision of communication equipment maintenance services by Sole Proprietor Anastasiia Zhoglikova (hereinafter referred to as the "Contractor") to any individual or legal entity that accepts the terms of this Agreement (hereinafter referred to as the "Customer").

        h3 1. GENERAL PROVISIONS
        div 1.1. This Agreement is a public offer in accordance with Articles 633, 641, and 642 of the Civil Code of Ukraine.
        div 1.2. Full and unconditional acceptance of the terms of this Agreement is constituted by the Customer submitting a request for services via the website https://easypon.in, a phone call, or any other means of communication with the Contractor.
        div 1.3. The Agreement is concluded without a written signature but holds the same legal force as a document signed by both parties.

        h3 2. SUBJECT OF THE AGREEMENT
        div 2.1. The Contractor agrees to provide the Customer with services related to the maintenance, repair, and configuration of communication equipment (hereinafter referred to as the "Services"), and the Customer agrees to accept and pay for these Services under the terms of this Agreement.
        div 2.2. The list of services, their cost, and other conditions are specified on the Contractor's website or agreed upon individually.

        h3 3. PROCEDURE FOR SERVICE PROVISION
        div 3.1. The Customer submits a request for Services via the Contractor's website, phone call, or other available means.
        div 3.2. The Contractor provides the Services within the timeframes agreed upon with the Customer.
        div 3.3. If necessary, the Contractor may visit the Customer's location to perform the work.

        h3 4. COST OF SERVICES AND PAYMENT PROCEDURE
        div 4.1. The cost of Services is determined according to the rates specified on the Contractor's website or agreed upon with the Customer separately.
        div 4.2. Payment is made via bank transfer to the Contractor's account or by another method agreed upon by the parties.
        div 4.3. The Customer agrees to make full payment before the Services are provided unless otherwise agreed upon by the parties.

        h3 5. RIGHTS AND OBLIGATIONS OF THE PARTIES
        div 5.1. Rights of the Contractor:
        div - To receive payment for the Services provided within the terms established by this Agreement.
        div - To refuse to provide Services in case of the Customer's violation of this Agreement
        div 5.2. Obligations of the Contractor:
        div - To provide the Services in a timely and high-quality manner as agreed with the Customer.
        div - To provide the Customer with consultations regarding the use of communication equipment.
        div 5.3. Rights of the Customer:
        div - To receive high-quality Services in accordance with the terms of this Agreement.
        div - To request the rectification of deficiencies caused by the Contractor's fault.
        div 5.4. Obligations of the Customer:
        div - To pay for the Contractor's Services on time.
        div - To provide the Contractor with access to the equipment requiring maintenance.

        h3 6. LIABILITY OF THE PARTIES
        div 6.1. The Contractor is not responsible for the quality of the equipment's performance if the Customer violates its operational rules or fails to follow the Contractor's recommendations.
        div 6.2. In case of payment delays, the Customer agrees to pay the Contractor a penalty of 0.1% of the outstanding amount for each day of delay.

        h3 7. FORCE MAJEURE
        div 7.1. The parties are exempt from liability for failure to fulfill or improper fulfillment of the terms of this Agreement in the event of force majeure circumstances (war, natural disasters, acts of government authorities, etc.).

        h3 8. TERM OF THE AGREEMENT
        div 8.1. This Agreement takes effect upon acceptance by the Customer and remains valid until the parties fulfill their obligations in full.

        h3 9. OTHER CONDITIONS
        div 9.1. The Contractor has the right to amend this Agreement by publishing a new version on the website.
        div 9.2. All disputes arising from the execution of this Agreement are resolved through negotiations, and if no agreement is reached, in court under Ukrainian law.

        h3 10. CONTRACTOR'S DETAILS
        div Sole Proprietor Anastasiia Zhoglikova
        div Address: Ukraine, 69059, Zaporizhzhia region, Zaporizhzhia city, Chumachenko street, building 40, apartment 114
        div Taxpayer Identification Number: 3518006101
        div Phone: +380931776917
        div Email: sales@easypon.in


</template>

<script>
export default {
  name: 'AgreementPage',
  metaInfo () {
    return this.$metaDefault({ robots: 'noindex, follow' })
  }
}
</script>
